const InitCourseSearchInput = () => {
  const calculatorForm = document.querySelector('#calculatorForm');

  function removeAllChildNodes(parent) {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  }

  if (calculatorForm) {
    const inputCourses = calculatorForm.querySelector('#multiStepCourse');
    const coursesList = calculatorForm.querySelector('.form__field-list-courses');

    const htmlToElem = (html) => {
      let temp = document.createElement("template");
      html = html.trim(); // Never return a space text node as a result
      temp.innerHTML = html;
      return temp.content.firstChild;
    };

    const chooseCourseElem = (e) => {
      let target = e.target;
      let value = target.innerText;
      inputCourses.value = value;

      if (window.matchMedia("(max-width: 767px)")) {
        coursesList.classList.remove('form__field-list-courses--opened');
      }
    };

    const searchCourse = (e) => {
      const target = e.target;
      let value = target.value;
      let courseItemsList;
      if (value.length > 2) {
        $.ajax({
          // инициализируем ajax запрос
          type: 'GET',
          // в каком формате отправляем
          url: '/lists/courses?query=' + value,
          success: function success(response) {
            coursesList.classList.add('form__field-list-courses--opened');
            let res = response;
            if (res.error) {
              removeAllChildNodes(coursesList)
              const courseItemHtml = "<li class='form__field-item-courses form__field-item-courses--disabled'>" + res.error + "</li>"
              const courseItem = htmlToElem(courseItemHtml);
              courseItem.addEventListener('click', (e) => chooseCourseElem(e));
              coursesList.append(courseItem);
              return;
            }
            if (res.list && res.list.length) {
              removeAllChildNodes(coursesList)
              courseItemsList = res.list.slice(0, 7);
              courseItemsList.map((item) => {
                let searchString = value.toLowerCase();
                let lengthString = searchString.length;
                let index = item.name.toLowerCase().indexOf(searchString) !== -1 ? item.name.toLowerCase().indexOf(searchString) : 0;
                let firstString = item.name.slice(0, index);
                let middleString = item.name.slice(index, index + lengthString);
                let lastString = item.name.slice(index + lengthString, undefined);
                let resultName = `${firstString}<span class="form__field-item-courses-highlighted">${middleString}</span>${lastString}`;
                const courseItemHtml = `<li class='form__field-item-courses' data-value='${item.id}'>${resultName}</li>`;
                const courseItem = htmlToElem(courseItemHtml);
                courseItem.addEventListener('click', (e) => chooseCourseElem(e));
                coursesList.append(courseItem);
              })
            }
            console.log(res);
          },
          error: function error(xhr, status, _error) {
            console.log(_error);
            console.log(xhr);
          }
        });
      } else {
        coursesList.classList.remove('form__field-list-courses--opened');
      }

    };

    document.addEventListener("click", (e) => {
      if (!e.target.closest('.form__field-wrapper--courses')) {
        coursesList.classList.remove('form__field-list-courses--opened');
      }
    })

    inputCourses.addEventListener('input', (e) => searchCourse(e));
  }

};

export default InitCourseSearchInput;
