import { getCookie, setCookie } from "../../utils/cookie";
import time from "../../utils/time";

//на выход
const initLeadHunter2Popup = () => {
  const leadHunter = document.querySelector('#popup-leadhunter2');
  let timeout;
  let after;
  let pages;

  if (leadHunter) {
    timeout = leadHunter.dataset.timeout;
    after = leadHunter.dataset.after;
    pages = leadHunter.dataset.pages;
  }

  function leadHunterPopup2() {
    let flag_check = false;

    if (!flag_check) {
      let show_time = parseInt(timeout);
      let value_show = parseInt(after);
      let flag_show = true;

      if (!isNaN(getCookie("showidgetgr2")) && parseInt(getCookie("showidgetgr2")) && (time() - parseInt(getCookie("showidgetgr2")) < value_show)) {
        flag_show = false;
      }

      console.log('show out leadhunter');
      if (show_time && flag_show) {
        setTimeout(function () {

          let count = 0;

          window.addEventListener('mousemove', function (e) {
            if (e.clientY < 10 && !count) {
              count++
              if ($("#popup-leadhunter2").length) {
                document.getElementById('lh1-closer').click()
                document.getElementById('lh2-opener').click()
                setCookie("showidgetgr2", time(), { 'path': '/', 'domain': 'stuservis.ru' });
                setCookie("showidgetgr", time(), { 'path': '/', 'domain': 'stuservis.ru' });
              }
            }
          });


        }, show_time * 1000);
      }
    }
  }

  if (leadHunter) {
    if (document.body.clientWidth > 1023) {
      leadHunterPopup2();
    }
  }
};

export default initLeadHunter2Popup;
