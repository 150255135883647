import { openLoaderGR } from "../loaders/loader";
import createUserOrder from "./createUserOrder";

const initSendToCrmSubscribeForm = () => {
  $('.sendToCrmSubscribe').validate({
    errorLabelContainer: $('.sendToCrmSubscribe .error-block'),
    rules: {
      email: {
        required: true,
        email: true,
      },
    },
    messages: {
      email: {
        required: 'Поле email обязательно для заполнения',
        email: 'Введите корректный email',
      },
    },
    submitHandler: function (form, e) {
      e.preventDefault();
      openLoaderGR();
      createUserOrder(form);
      return false;
    },
  });
};

export default initSendToCrmSubscribeForm;
