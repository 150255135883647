// Swiper 7.4.1
// import './vendor/swiper';
import "./vendor/focus-visible-polyfill";
import 'bootstrap';
import 'popper.js';
import "owl.carousel";
import "jquery-validation";
import './vendor/select2.min.js';
import "./vendor/jquery.selectric.min.js";
import './vendor/messages_ru';
import './vendor/select2_ru';
import IMask from "imask";
