const initDropDownList = () => {
  $('.dropdown__button').on('click', function () {

    if ($('.dropdown__list').hasClass('show')) {
      $('.dropdown__list').removeClass('show');
    }

    else {
      $('.dropdown__list').addClass('show');
    }

  });

  $(document).on('mouseup', function (e) {
    const container = $('.dropdown__list');
    if (container.hasClass('show')) {
      if (container.has(e.target).length === 0) {
        container.removeClass('show');
      }
    }

  });
};

export default initDropDownList;
