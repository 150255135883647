const checkEmailCreateOrderForm = () => {
  const $modalCO = $('#createOrder');


  $modalCO.find('#new-order-email').on('input', function () {
    if (this.checkValidity()) {
      $(this).removeClass('is-invalid');
    } else {
      $(this).addClass('is-invalid');
    }
  });
};

export default checkEmailCreateOrderForm;
