
const initButtonsOnClickDownload = () => {
  const buttonsDownloadOnClick = document.querySelectorAll('.goods__button.goods__button--download');
  const popupDownload = $('#popup-download');

  const createOrderGR = (orderId, theme) => {
    popupDownload.find('.js-theme').html(theme);
    popupDownload.data('orderId', orderId);
    popupDownload.find('.js-message').hide();
    popupDownload.find('.modal-footer, .close, .js-email').show();
  }

  if (buttonsDownloadOnClick && buttonsDownloadOnClick.length) {
    Array.from(buttonsDownloadOnClick).map((item) => {
      const orderId = item.dataset.orderId;
      const orderTheme = item.dataset.orderTheme;
      item.addEventListener('click', () => {
        createOrderGR(orderId, orderTheme);
        return false
      })
    })
  }
}


export default initButtonsOnClickDownload
