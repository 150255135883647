const initHandlerCloseModal = () => {
  $(".close-modal").on('click', function (e) {
    e.preventDefault();
    e.stopImmediatePropagation;

    const $this = $(this),
      modal = $($this).data("modal");

    $(modal).removeClass("open");
    setTimeout(function () {
      $(modal).parents(".overlay").removeClass("open");
    }, 350);

  });
};

export default initHandlerCloseModal;
