import { openLoaderGR } from "../loaders/loader";
import sendToCrmHandler from "./sendToCrmHandler";

const initSendToCrmFromMainForm = () => {
  $('.sendToCrmFromMain').validate({
    errorLabelContainer: $('.sendToCrmFromMain .error-block'),
    rules: {
      theme: 'required',
      email: {
        required: true,
        email: true,
      },
      typeOfWork: {
        required: true,
      },
      agree: {
        required: true,
      },
    },
    messages: {
      theme: 'Заполните поле "Тема работы"',
      email: {
        required: 'Поле email обязательно для заполнения',
        email: 'Введите корректный email',
      },
      typeOfWork: {
        required: 'Поле "Тип работы" обязательно для заполнения',
      },
      agree: {
        required: 'Подтвердите согласие на обработку персональных данных',
      },
    },
    submitHandler: function (form, e) {
      e.preventDefault();
      openLoaderGR();
      sendToCrmHandler(form);
      return false;
    },
  });
};

export default initSendToCrmFromMainForm;
