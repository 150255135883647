const initTabs = () => {
  const tabTrigger = document.querySelectorAll('.tabs__item');
  const tabTrigger2 = document.querySelectorAll('.carousel__dots .owl-dot');

  if (tabTrigger) {
    tabTrigger.forEach(function (tabTrigger, index) {
      tabTrigger.addEventListener('click', function (e) {
        e.preventDefault();

        let currentTab = document.querySelector('.card__slide[data-tab-content="' + this.dataset.tabCategory + '"]');

        if (currentTab) {
          document.querySelector('.card__slide.is-open').classList.remove('is-open');
          document.querySelector('.tabs__item.tabs__item_active').classList.remove('tabs__item_active');

          if (!currentTab.classList.contains('is-open')) {
            currentTab.classList.add('is-open');
            this.classList.add('tabs__item_active');
          }
        }
      });

    });

    tabTrigger2.forEach(function (tabTrigger2, index) {
      tabTrigger2.addEventListener('click', function (e) {
        e.preventDefault();

        let currentTab = document.querySelector('.card__slide[data-tab-content="' + this.dataset.tabNav + '"]');

        if (currentTab) {
          document.querySelector('.card__slide.is-open').classList.remove('is-open');
          document.querySelector('.carousel__dots .owl-dot.active').classList.remove('active');

          if (!currentTab.classList.contains('is-open')) {
            currentTab.classList.add('is-open');
            this.classList.add('active');
          }
        }
      });

    });


  }
};

export default initTabs;
