const initCalcPopupScript = () => {
  $(".checkPrice").on('click', function () {
    const priceStr = $("select#type_of_works_select option:selected").data("price");
    const arr = priceStr.split("/");
    $(this).hide();
    $('.hiddenCalcForm').show();
    $(".footer__popup__content__price i").text(arr[0] + " *");
  });
};

export default initCalcPopupScript;
