import { closeLoaderGR } from "../loaders/loader";

const createUserOrder = (form) => {
  const token = $('meta[name="csrf-token"]').attr('content');
  const submitBtn = $(form).find('button[type="submit"]');
  submitBtn.prop('disabled', true);
  let formData = new FormData(form);

  fetch('/forms/create-user/', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json, text-plain, */*",
      "X-Requested-With": "XMLHttpRequest",
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify(Object.fromEntries(formData))
  }).then(response => response.json())
    .then((result) => {
      if (typeof result.authlink !== 'undefined') {
        window.location.href = result.authlink + 'firstOrder';
      } else {
        window.location.href = 'https://studservis-lk.ru/orders/edit/';
      }
      // console.log(result);
    }).catch((error) => {
      console.log(error);
      closeLoaderGR();
      alert('Произошла ошибка');
      // window.location.reload();
    })
  submitBtn.prop('disabled', false);
};

export default createUserOrder;
