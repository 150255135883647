const closePopups = () => {
  $(".popup_shade").removeClass("show");
  $(".popup").removeClass("show");
  $('body').removeClass("overflow").css('margin-right', '');
  $('.header').removeClass("overflow").css('margin-right', '');

};

const initShowPopups = () => {
  const hamburgers = document.querySelectorAll(".hamburger");
  const wrap = document.querySelector('.wrapper');
  const body = document.querySelector('body');
  const nav = document.querySelector('nav');
  $(".show-popup").on('click', function (e) {

    e.preventDefault();

    const t = $($(this).attr("href"));
    const width = $('body').width();
    const bodyMain = $('.body-main');

    $(".popup_shade").addClass("show");

    if (body.classList.contains('body-main')) {
      $('body').css('margin-right', 0 + 'px');
      $('body').css('padding-right', 0 + 'px');
      console.log('main');
    }

    if (wrap.classList.contains('open-sidebar')) {
      wrap.classList.remove('open-sidebar');
      hamburgers[0].classList.remove('is-active');
      body.classList.remove('overflow');
      nav.classList.remove('show');
    }

    const scrollWidth = window.innerWidth - document.documentElement.clientWidth;
    const headerIsFixed = $('.header').css('position') === 'fixed';

    $('body').addClass("overflow").css('margin-right', scrollWidth + 'px');
    $('.header').addClass("overflow").css('margin-right', headerIsFixed ? scrollWidth + 'px' : '');

    if ($(this).data("item")
      && t.find('input[name="item"]').val($(this).data("item")), $(this).data("product")
      && t.find('input[name="product_type"]').val($(this).data("product")), $(this).data("item_id")
      && t.find('input[name="item_id"]').val($(this).data("item_id")), t.addClass("show"), t.find("video").length
    ) try {
      t.find("video")[0].play()
    } catch (e) {
      console.log(e)
    }

  });

  $(".popup__close-btn, .popup-close, .popup_shade").click(function (e) {
    e.preventDefault();
    closePopups();
  });

};

export default initShowPopups;
