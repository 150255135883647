import { openLoaderGR } from "../loaders/loader";
import sendToCrmHandler from "./sendToCrmHandler";

const initSendToCrmForm = () => {
  $('.sendToCrm').each(function () {
    $(this).validate({
      rules: {
        theme: 'required',
        email: {
          required: true,
          email: true,
        },
        typeOfWork: {
          required: true,
        },
        agree: {
          required: true,
        },
      },
      messages: {
        typeOfWork: 'Заполните поле "Тип работы"',
        theme: 'Заполните поле "Тема работы"',
        email: {
          required: 'Поле email обязательно для заполнения',
          email: 'Введите корректный email',
        },
        agree: {
          required: 'Подтвердите согласие на обработку персональных данных',
        },
      },
      errorPlacement: function (error, element) {
        if (element.attr('name') == 'agree') {
          $('.popup__term-error').html(error);
        } else {
          error.insertAfter(element);
        }
      },
      submitHandler: function (form, e) {
        e.preventDefault();
        openLoaderGR();
        sendToCrmHandler(form);
        return false;

      },
    });
  });
};

export default initSendToCrmForm;
