const initSearchGoods = () => {
  if ($('.search-goods').length > 0) {

    $('.icon-search.title__icon').on('click', function () {
      $(this).parent().siblings('.goods__search').siblings().addClass('search-active');
      $(this).parent().siblings('.goods__search').addClass('search-show');
    });

    $('.search-goods__close').on('click', function () {
      $(this).parent().siblings().removeClass('search-active');
      $(this).parent().removeClass('search-show');
    });
  }
};

export default initSearchGoods;
