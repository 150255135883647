const closeLoaderGR = () => {
  document.querySelector('#loaderPopup').classList.remove('show');
  document.querySelector('body').style.overflow = 'visible';
};

const closeLoaderPopup = (popup) => {
  popup.removeClass("open");
  $("body").removeClass("overflow-hidden");
};

const openLoaderGR = () => {
  document.querySelector('#loaderPopup').classList.add('show');
  document.querySelector('body').style.overflow = 'hidden';
};

const openLoaderPopup = (popup) => {
  popup.addClass("open");
  $("body").addClass("overflow-hidden");
};

export { closeLoaderGR, closeLoaderPopup, openLoaderGR, openLoaderPopup }
