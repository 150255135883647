const initFilterTitleMobile = () => {
  if ($('.filter__title_mobile').length > 0) {
    $('.filter__title_mobile').on('click', function () {
      const hidden = $(this).siblings('.filter__hidden');
      hidden.toggleClass('show');
    })
  }
};

export default initFilterTitleMobile;
