const initHandlerOpenModal = () => {
  $(".open-modal").on('click', function (e) {
    let heightH1 = $(".promo__title.title.title_h1").height();
    if ($(".switch_modal").hasClass("open")) {
      $(".switch_modal").removeClass("open");
      $(".switch_modal").css("top", "-99999px");
    } else {
      $(".switch_modal").addClass("open");
      $(".switch_modal").css("top", `${heightH1}px`);
    }
    e.preventDefault();
    e.stopImmediatePropagation;

    const $this = $(this),
      modal = $($this).data("modal");

    $(modal).parents(".overlay").addClass("open");
    setTimeout(function () {
      $(modal).addClass("open");
    }, 350);

    $(document).on('click', function (e) {
      const target = $(e.target);

      if ($(target).hasClass("overlay")) {
        $(target).find(".modal").each(function () {
          $(this).removeClass("open");
        });
        setTimeout(function () {
          $(target).removeClass("open");
        }, 350);
      }

    });
  });
};

export default initHandlerOpenModal;
