const createPhoneMask = (elem) => {
  const maskInput = IMask(elem, {
    mask: "+{7} (#00) 000-00-00",
    definitions: {
      "#": /[0-69]/,
    },
    lazy: true, // make placeholder always visible
  });

  elem.addEventListener("keypress", function () {
    maskInput.updateOptions({ lazy: false });
  });
};

const initPhoneMasks = () => {
  // Начало Создание маски для формы ввода телефона
  if (document.querySelector("#phonefield")) {
    createPhoneMask(document.querySelector("#phonefield"));
  }

  if (document.querySelector("#popupphone")) {
    createPhoneMask(document.querySelector("#popupphone"));
  }

  if (document.querySelector("#partnerphonefield")) {
    createPhoneMask(document.querySelector("#partnerphonefield"));
  }
  // Конец создания маски для формы ввода телефона
};

export { initPhoneMasks };
