import makeOrderGR from "./makeOrderGR";

const initModalCreateOrder = () => {
  const createOrderFromModal = (e) => {
    e.preventDefault();
    const email = popupDownload.find('#new-order-email')[0];
    if (!email.checkValidity()) {
      return;
    }
    makeOrderGR(popupDownload.data('orderId'), email.value);
  }

  const popupDownload = $('#popup-download');
  const butttonSendModalData = document.querySelectorAll('.form__order-modal');

  if (butttonSendModalData && butttonSendModalData.length) {
    Array.from(butttonSendModalData).map((item) => {
      item.addEventListener('submit', (e) => createOrderFromModal(e))
    });
  }
};

export default initModalCreateOrder;
