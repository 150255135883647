const initHamburgerModalRemover = () => {
  $(document).on('mouseup', function (e) {
    const popup = $(".hamburger__list");
    const modal = $(".switch_modal");
    if (!$('.hamburger__list.show').is(e.target) && !popup.is(e.target) && popup.has(e.target).length == 0) {
      popup.removeClass('show');
    }
    if (!$('.switch_modal.open').is(e.target) && !modal.is(e.target) && modal.has(e.target).length == 0) {
      modal.removeClass('open');
    }
  });
};

export default initHamburgerModalRemover;
